// import { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Link } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoopSwiper from './loopSwiper';
import React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

import { discord, linktree, roadmap } from './assets';

export interface FooterProps {}

const Footer = (props: FooterProps) => {
  const [expanded, setExpanded] = React.useState('panel0');
  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="layout loopSwiper" id="LoopSwiper">
        <h2 className="title">SNEAK PEEK</h2>
        <LoopSwiper />
      </div>

      <div className="layout faq" id="FAQ">
        <h2 className="title">FREQUENTLY ASKED QUESTIONS</h2>

        <Accordion className="faq-accordion" expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="faq-title">
              In addition to the tokens, will I also own the NFT that comes with participating in the public offering?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faq-text">
              Of course, BRO! In addition to the public offering token airdrop to your SOL address, this limited-edition
              public offering NFT will also be yours.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="faq-accordion" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="faq-title">What is an NFT?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faq-text">
              {/* AN NFT IS A NEW TYPE OF IMAGE FORMAT SCAM. JPGES THAT PROMISE PROMISING MEMBERSHIPS, IF YOU DON'T
              UNDERSTAND WHAT NFT IS, PLEASE DON'T PARTICIPATE, IT MAY BE A SCAM, NO, IT IS A SCAM, I MEAN ALL NFT
              PROJECTS. */}
              THE ADDITIONAL NFT REWARDS YOU WILL RECEIVE FOR PARTICIPATING IN THE PUBLIC OFFERING WILL: SERVE AS A
              SYMBOL OF YOUR COMMUNITY IDENTITY. ALLOW YOU TO ENJOY AIRDROP BENEFITS FROM THE PROJECT. BE TRADABLE ON
              THE SECONDARY MARKET.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="faq-accordion" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography className="faq-title"> Is there a whitelist? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faq-text">NO.</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="faq-accordion" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="faq-title">
              How can I help you to promote and hype this project together ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faq-text">
              HELP US PROMOTE AND PROMOTE OUR PROJECT, FOLLOWING US ON TWITTER
              <a className="faq-link" target="_blank" href="https://twitter.com/small_monkeys" rel="noreferrer">
                @small_monkeys
              </a>
              , RETWEETING OUR TWITTER, AND @ YOUR FRIENDS,THE EARLIEST 2K USERS CAN MINT AT A DISCOUNTED PRICE.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="faq-accordion" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="faq-title">What is the Roadmap?</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ height: 700 }}>
            <img src={roadmap} alt="roadmap" className="roadmap" />
          </AccordionDetails>
        </Accordion>

        <Accordion className="faq-accordion" expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography className="faq-title"> Will there be market making and price underpinnings ? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faq-text">YES.</Typography>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion className="faq-accordion" expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography className="faq-title">Is there Discord? How can I enter?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faq-text">
              YES, WE CREATED A DISCORD, BUT LET'S BE HONEST, WE'RE NOT GOOD AT THIS, SO OUR DISCORD IS A WILD
              WASTELAND, YOU CAN GO AND LOOK AROUND IF YOU WANT.
              <a className="faq-link" target="_blank" href="https://discord.gg/" rel="noreferrer">
                ApeHypeClub
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        <Accordion className="faq-accordion" expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography className="faq-title">Who is the team behind the project?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faq-text">
              MY TRADER FRIENDS AND I, A BUNCH OF CRAZY SPECULATORS, HYPE MARKETING GURUS.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="faq-accordion" expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography className="faq-title">
              I heard that there is a small Easter EGG in this project, what is it?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faq-text">
              YES, WE HAVE SET UP A SMALL EASTER EGG IN THE PROJECT. ANYONE WHO MINT HAS A LITTLE FROG ABOVE HIS HEAD
              WILL BE REWARDED WITH 10 SOL. GOOD LUCK.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="layout footer">
        <div className="navbar ">
          <Link className="navbar-item" href="#ABOUT">
            About
          </Link>
          <Link className="navbar-item" href="#MINT">
            MINT
          </Link>
          <Link className="navbar-item" href="#FAQ">
            FAQ
          </Link>

          <Link className="navbar-item" target="_blank" rel="noopener" href="https://solo.to/">
            solo.to
          </Link>

          <Link className="navbar-item" target="_blank" rel="noopener" href="https://opensea.io/">
            OpenSea
          </Link>

          <Link className="navbar-item" target="_blank" rel="noopener" href="https://magiceden.io/">
            MagicEden
          </Link>
        </div>

        <div className="navbar">
          <Link className="navbar-item" target="_blank" rel="noopener" href="https://twitter.com/small_monkeys">
            <TwitterIcon />
          </Link>
          <Link
            className="navbar-item"
            target="_blank"
            rel="noopener"
            href="/#"
            data-href="https://instagram.com/small_monkeys_sol">
            <InstagramIcon />
          </Link>

          <Link
            className="navbar-item"
            target="_blank"
            rel="noopener"
            href="/#"
            data-href="https://discord.gg/small_monkeys_sol">
            <img style={{ width: 24 }} src={discord} alt="discord" />
          </Link>

          <Link
            className="navbar-item"
            target="_blank"
            rel="noopener"
            href="/#"
            data-href="https://linktr.ee/small_monkeys_sol">
            <img style={{ width: 24 }} src={linktree} alt="linktree" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
