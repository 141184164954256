import React from 'react';

interface LoopTextProps {
  //   height: number; // 容器的高度
  width: number; // 容器的高度
  speed: number; // 滚动的速度，越大则滚动的越快，反之越慢
}

export default class LoopText extends React.Component<LoopTextProps> {
  state = {
    rollClass: '',
  };
  setLoopTextStyle = (offsetWidth: number, speed: number) => {
    const uid = Math.random().toString(36).substr(2);
    const style = document.createElement('style');
    style.type = 'text/css';

    style.innerHTML = `@-webkit-keyframes rowup${uid} {
            0% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            100% {
                -webkit-transform: translate3d(-50%, 0, 0);
                transform: translate3d(-50%, 0, 0);
            }
        }
        @keyframes rowup${uid} {
            0% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            100% {
                -webkit-transform: translate3d(-50%, 0, 0);
                transform: translate3d(-50%, 0, 0);
            }
        }
        .rowup-${uid}{
            -webkit-animation: ${Math.floor((offsetWidth * 1000) / speed)}ms rowup${uid} linear infinite normal;
            animation: ${Math.floor((offsetWidth * 1000) / speed)}ms rowup${uid} linear infinite normal;
        }`;
    document.getElementsByTagName('head')[0].appendChild(style);
    return `rowup-${uid}`;
  };

  componentDidMount() {
    const loopTextContent: HTMLElement | null = document.querySelector('.loopText .loopText-content');
    if (loopTextContent) {
      const offsetWidth = loopTextContent.offsetWidth;

      const loopTextClass = this.setLoopTextStyle(offsetWidth / 2, this.props.speed || 60);
      this.setState({
        rollClass: loopTextClass,
      });
    }
  }
  render() {
    const rollClass = this.state.rollClass ? ' ' + this.state.rollClass : '';
    return (
      <div className="loopText" style={{ width: this.props.width }}>
        <div className={'loopText-content' + rollClass}>
          {this.props.children}
          {this.props.children}
        </div>
      </div>
    );
  }
}
