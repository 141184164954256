import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Container, Link, Snackbar } from '@material-ui/core';

import Confetti from 'react-confetti';

import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

import LoopText from './loopText';
import {
  warn,
  discord,
  linktree,
  banana,
  banner,
  previewImg33,
  previewImg35,
  previewImg39,
  previewImg40,
  previewImg41,
  previewImg42,
} from './assets';

export interface HeaderProps {
  //   candyMachineId?: anchor.web3.PublicKey;
  //   connection: anchor.web3.Connection;
  //   txTimeout: number;
  //   rpcHost: string;
  //   network: WalletAdapterNetwork;
  //   error?: string;
}

const Header = (props: HeaderProps) => {
  const [party, setParty] = useState(false);

  return (
    <div>
      <div className="layout header">
        <div className="navbar ">
          <Link className="navbar-item" href="#ABOUT">
            ABOUT
          </Link>
          <Link className="navbar-item" href="#MINT">
            MINT
          </Link>
          <Link className="navbar-item" href="#FAQ">
            FAQ
          </Link>
        </div>
        <Confetti
          style={{ pointerEvents: 'none' }}
          numberOfPieces={party ? 500 : 0}
          recycle={false}
          onConfettiComplete={(confetti) => {
            // confettiCompleteAction()
            // setParty(false);
            // console.log(` \n\n 777onConfettiComplete ${party} `);
            // confetti.reset();
          }}
        />
        <div className="navbar">
          <Link className="navbar-item navbar-logo" rel="noopener" onClick={() => setParty(!party)}>
            <img id="logo" className="logo" src={banana} alt="ApeHypeClub" />
          </Link>
        </div>

        <div className="navbar">
          <Link className="navbar-item" target="_blank" rel="noopener" href="https://twitter.com/small_monkeys">
            <TwitterIcon />
          </Link>
          <Link className="navbar-item" rel="noopener" href="/#" data-href="https://instagram.com/small_monkeys">
            <InstagramIcon />
          </Link>
          <Link className="navbar-item" rel="noopener" href="/#" data-href="https://discord.gg/small_monkeys_sol">
            <img style={{ width: 24 }} src={discord} alt="discord" />
          </Link>

          <Link className="navbar-item" rel="noopener" href="/#" data-href="https://linktr.ee/small_monkeys">
            <img style={{ width: 24 }} src={linktree} alt="linktree" />
          </Link>
        </div>
      </div>

      <div className="layout banner ">
        <img style={{ minHeight: 120 }} src={banner} alt="banner" />
      </div>

      <div className="loop slogan">
        <LoopText width={1200} speed={40}>
          <img className="loop-warn" src={warn} alt="warning" />
          <p className="loop-desc">
            Non-speculators please stay away, Major speculative play MLM. 🍌 Non-speculators please stay away, Major
            speculative play MLM. 🍌 Non-speculators please stay away, Major speculative play MLM. 🍌 Non-speculators
            please stay away, Major speculative play MLM. 🍌
          </p>
          <img className="loop-warn" src={warn} alt="warning" />
          <p className="loop-desc">
            Non-speculators please stay away, Major speculative play MLM. 🍌 Non-speculators please stay away, Major
            speculative play MLM. 🍌 Non-speculators please stay away, Major speculative play MLM. 🍌 Non-speculators
            please stay away, Major speculative play MLM. 🍌
          </p>
        </LoopText>
      </div>
      {/* <div className="warn hide">
        <img className="desc-warn" src={warn} alt="warning" />
        <span className="warn-desc">Non-speculators please stay away, Major speculative play MLM. </span>
        <img className="desc-warn" src={warn} alt="warning" />
        <span className="warn-desc">Non-speculators please stay away, Major speculative play MLM. </span>
        <img className="desc-warn" src={warn} alt="warning" />
        <span className="warn-desc">Non-speculators please stay away, Major speculative play MLM. </span>
        <img className="desc-warn" src={warn} alt="warning" />
        <span className="warn-desc">Non-speculators please stay away, Major speculative play MLM. 🍌</span>
      </div> */}

      <div id="ABOUT" className="layout about">
        {/* about-info */}
        <div className="about-info">
          <div className="about-left">
            <h2 className="title">WHAT IS SMALL MONKEYS?</h2>
            <p className="text">
              We have innovatively created a new model for token public offering distribution, by publicly casting NFTs
              as a way to participate in public offering tokens during the public offering stage. Each NFT token
              represents a user's public offering investment, which according to the plan corresponds to 100 million
              tokens.
            </p>

            <p className="text">
              Each investor who participates in the public offering will receive an airdrop of corresponding tokens
              according to the number of NFTs they hold after the public offering ends. At the same time, NFTs are also
              a symbol and honor record for early public offering users. In the later stage, there will be various
              airdrops and rewards, and the project income funds will be used to launch the NFT buyback plan.
            </p>

            <p className="text">
              To achieve this goal, we issued 9996 Little Monkey NFTs. The price of each mint is set at 0.5 SOL. All the
              mint funds of the project will be used for token airdrops, liquidity pool creation, market marketing and
              promotion.
            </p>
          </div>

          <div className="about-side">
            <ul>
              <li>
                <img className="preview-mini" alt="preview" src={previewImg39} />
              </li>
              <li>
                <img className="preview-mini" alt="preview" src={previewImg40} />
              </li>
              <li>
                <img className="preview-mini" alt="preview" src={previewImg41} />
              </li>
              <li>
                <img className="preview-mini" alt="preview" src={previewImg42} />
              </li>
            </ul>
          </div>
        </div>

        {/* about-sale */}
        <div className="about-sale">
          <div className="about-left">
            <img className="preview" alt="preview" src={previewImg35} />
          </div>

          <div className="about-side">
            <h2 className="title">PUBLIC OFFERING PLAN & OFFERING PRICING </h2>
            <p className="text">
              We are launching a new type of gameplay that combines MEME Tokens and NFTs on the Solana chain. We plan to
              issue a total of 1000 billion SPL Token tokens, of which 50% will be offered to the public.
            </p>
            <p className="text">
              The public offering will be 500 billion tokens, divided into 5000 share, each with 100,000,000 tokens.
            </p>
            <p className="text">
              The public offering price is 0.5 SOL for 100 million TOKENS. Public offering participants will receive
              additional public offering NFTs as rewards.
            </p>
          </div>
        </div>

        {/* about-buy */}
        <div className="about-buy">
          <div className="about-left">
            <h2 className="title">HOW TO PARTICIPATE IN A PUBLIC OFFERING??</h2>

            <h3 className="title">· Phase 1: EARLY BIRD MINTING () - TILL SOLD OUT</h3>
            <p className="text">
              Open to early followers of the project, at this stage, the project is in the early stage of publicity, and
              the early birds participating will be MINT at the price of 0.5 SOL to give back to those who participated
              in the project early. If you discovered this project before the 17th, congratulations, you're an early
              bird.
            </p>

            <h3 className="title">
              · Phase 2: PUBLIC MINTING ( 3 September 4 PM EST to 8 September 4 PM)-TILL SOLD OUT
            </h3>
            <p className="text">
              Starting September 3 at 4PM EST, anyone can mint at PRICE:2 SOL until MINT OUT, or September 8 to burn all
              remaining NFTs.
            </p>

            <h3 className="title">· Phase 3: COMPLETE THE SUPPLY (START HYPE AND MARKET MAKING)</h3>
            <p className="text">
              When the public MINT is completed, any remaining NFT will be destroyed to complete the first deflation.
              The team started automatic market making in MagicEden, and let me achieve the first great goal together,
              the floor price of 100 SOL.
            </p>

            <h3 className="title">· Phase 4: COMPLETE THE SUPPLY (START HYPE AND MARKET MAKING)</h3>
            <p className="text">
              When the public MINT is completed, any remaining NFT will be destroyed to complete the first deflation.
              The team started automatic market making in MagicEden, and let me achieve the first great goal together,
              the floor price of 100 SOL.
            </p>
          </div>

          <div className="about-side">
            <img className="preview" alt="preview" src={previewImg33} />
          </div>
        </div>
      </div>

      <div className="loop slogan">
        <LoopText width={1200} speed={30}>
          <img className="loop-warn" src={warn} alt="warning" />
          <p className="loop-desc">
            Non-speculators please stay away, Major speculative play MLM. 🍌 Non-speculators please stay away, Major
            speculative play MLM. 🍌 Non-speculators please stay away, Major speculative play MLM. 🍌 Non-speculators
            please stay away, Major speculative play MLM. 🍌
          </p>
          <img className="loop-warn" src={warn} alt="warning" />
          <p className="loop-desc">
            Non-speculators please stay away, Major speculative play MLM. 🍌 Non-speculators please stay away, Major
            speculative play MLM. 🍌 Non-speculators please stay away, Major speculative play MLM. 🍌 Non-speculators
            please stay away, Major speculative play MLM. 🍌
          </p>
        </LoopText>
      </div>

      <div id="MINT"></div>
    </div>
  );
};

export default Header;
