import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import {
  previewImg44,
  previewImg45,
  previewImg46,
  previewImg47,
  previewImg48,
  previewImg49,
  previewImg50,
  previewImg51,
  previewImg52,
  previewImg53,
  previewImg54,
  previewImg55,
  previewImg56,
  previewImg57,
  previewImg58,
  previewImg59,
  previewImg60,
  previewImg61,
  previewImg62,
  previewImg63,
  previewImg64,
  previewImg65,
  previewImg66,
  previewImg67,
  previewImg68,
  previewImg69,
  previewImg70,
} from './assets';
// Import Swiper styles

const LoopSwiper = () => {
  return (
    <>
      <Swiper
        spaceBetween={20}
        slidesPerView={4}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        speed={300}
        // onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg44} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg45} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg46} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg47} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg48} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg49} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg50} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg51} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg52} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg53} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg54} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg55} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg56} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg57} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg58} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg59} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg60} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg61} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg62} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg63} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg64} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg65} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg66} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-item" src={previewImg67} alt="" />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default LoopSwiper;
